import React from "react";
import style from "./Team.module.scss";
import Section from "../../atoms/Section/Section";
import { ContainerRow } from "../../atoms/Containers/Conainers";
import ProfileCard from "../../elements/ProfileCard/ProfileCard";

const Team = () => (
  <Section>
    <ContainerRow>
      <div className='col-12 text-center'>
        <img src="/team/team.jpg" style={{maxWidth: "100%"}} className="img-fluid boxed-image"></img>

      </div>
      {/* <div className='col-12 col-md-4 mt-5 mt-md-0'>
        <ProfileCard name='Stefano Rosso' position='Co-Founder & CTO' image='/team/Stefano_bw.jpg' />
      </div>
      <div className='col-12 col-md-4 mt-5 mt-md-0'>
        <ProfileCard name='Alessia Arbore' position='Affiliate Manager' image='/team/Alessia_bw.jpg' />
      </div>
      <div className='col-12 col-md-4 mt-5 mt-md-0'>
        <ProfileCard name='Benedetta Aondio Bertero' position='Responsabile New Business' image='/team/benedetta_bw.jpg' />
      </div> */}
    </ContainerRow>
  </Section>
);

export default Team;
