import React, { useState } from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import ContactForm from "../components/components/ContactForm/ContactForm";
import Numbers, { Number } from "../components/components/Numbers/Numbers";
import Text from "../components/atoms/Text/Text";
import { scrollToContact } from "../utility/function";
import CentralLayout from "../components/atoms/CentralLayout/CentralLayout";
import Section from "../components/atoms/Section/Section";
import GreenTitle from "../components/atoms/GreenTitle/GreenTitle";
import UnderlineTitle from "../components/atoms/UnderlineTitle/UnderlineTitle";
import { ContainerRow } from "../components/atoms/Containers/Conainers";
import Team from "../components/components/Team/Team";

const ChiSiamo = () => {
  const [formOpen, setFormOpen] = useState(false);

  const toContatti = () => {
    setFormOpen(true);
    scrollToContact();
  };

  return (
    <Page title='Chi siamo' description='Misuriamo il nostro successo con il valore aggiunto che portiamo alla tua attività' image='/favicon.png'>
      <Layout navigationCallback={() => toContatti()}>
        {/* navigation space */}
        <Space mobile={15} table={15} desktop={15} />

        <Space mobile={10} table={10} desktop={10} />

        <CentralLayout>
          <GreenTitle caps>Chi siamo</GreenTitle>
          <h1>Misuriamo il nostro successo con il valore aggiunto che portiamo alla tua attività</h1>

        </CentralLayout>

        <Space mobile={10} table={15} desktop={20} />

        <ContainerRow>
          <div className='col-12 col-md-6'>
            <UnderlineTitle>La nostra storia</UnderlineTitle>
            <Text>
              E-leads nasce nel giugno 2018, come conseguenza della precedente esperienza dei suoi fondatori, già founders di una startup fintech. Partita come startup innovativa,
              a seguito della crescita rapida del business, si è trasformata oggi in una PMI innovativa, con una forte traction e una crescita costante, a doppia cifra, YoY delle revenues.
            </Text>
          </div>
          <div className='col-12 col-md-6 mt-8 mt-md-0'>
            <UnderlineTitle>Il nostro metodo</UnderlineTitle>
            <Text>
              Siamo una tech-first company. Applichiamo la nostra tecnologia al performance marketing. Tutti i nostri prodotti vengono sviluppati internamente,
              il che ci permette di muoverci rapidi e realizzare da soli le soluzioni ai problemi dei nostri clienti, adottando un approcio LEAN nel nostro lavoro quotidiano.
              Da settembre 2022, E-Leads è entrata a far parte di Advice Group.
            </Text>
          </div>
        </ContainerRow>

        <Space mobile={20} table={20} desktop={20} />

        <Team />

        <Space mobile={20} table={20} desktop={20} />
        <Space mobile={0} table={10} desktop={10} />

        <Numbers text={<>Crediamo in un'azienda<br />fatta di persone</>}>
          <Number number={"6"} text={"Persone nel team"} />
          <Number number={"<35"} text={"Età media del team"} />
          <Number number={"460+"} text={"Caffé presi"} />
        </Numbers>

        <Space mobile={10} table={10} desktop={10} />

        <Section background>
          <div id='contact-form'>
            {!formOpen && (
              <CentralLayout>
                <Space mobile={12} table={18} desktop={18} />
                <h3 className='mb-1'>Hai domande su di noi?</h3>
                <h4 className='my-0'>Non esitare</h4>
                <Button customClass='mt-6' black onClick={() => setFormOpen(true)}>
                  Contattaci
                </Button>
                <Space mobile={12} table={18} desktop={18} />
              </CentralLayout>
            )}
            {formOpen && <ContactForm />}
          </div>
        </Section>
      </Layout>
    </Page>
  );
};

export default ChiSiamo;
